<template>
  <div class="estimate">
    <Title />

    <section class="estimate-container">
      <div class="form-container">
        <div class="estimate-title">{{ $t("estimate_title") }}</div>
        <div class="form">
          <!-- name -->
          <section>
            <div class="form-sub-title">
              <span>{{ $t("form_name") }}</span>
              <span class="danger-text">
                {{ $t("form_require") }}
              </span>
            </div>

            <v-text-field
              outlined
              dense
              :label="$t('name_placeholder')"
              v-model="name"
              :rules="rules.name"
            ></v-text-field>
          </section>

          <!-- phone -->
          <section>
            <div class="form-sub-title">
              <span>{{ $t("form_contact_phone") }}</span>
              <span class="danger-text">
                {{ $t("form_require") }}
              </span>
            </div>

            <v-text-field
              outlined
              dense
              :label="$t('phone_placeholder')"
              v-model="phone"
              :rules="rules.phone"
            ></v-text-field>
          </section>

          <!-- email -->
          <section>
            <div class="form-sub-title">
              <span>{{ $t("estimate_mail") }}</span>
              <span class="danger-text">
                {{ $t("form_require") }}
              </span>
            </div>

            <v-text-field
              outlined
              dense
              :label="$t('email_placeholder')"
              v-model="email"
              :rules="rules.email"
            ></v-text-field>
          </section>

          <section class="form-send">
            <v-dialog v-model="confDialog" persistent max-width="800">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="form-send-button"
                  @click="checkForm()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="text_to_send">{{ $t("form_send") }}</span>
                </div>
              </template>

              <v-card>
                <div v-if="errorFlag">
                  <v-card-title class="text-h5 pl-2">{{
                    $t("error_label")
                  }}</v-card-title>
                  <div class="pt-2 pl-2 danger-text">
                    {{ $t("validate_postCode") }}
                  </div>
                </div>

                <v-card-title v-else class="text-h5">{{
                  $t("post_label")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed @click="confDialog = false">
                    {{ errorFlag ? $t("previos_btn_label") : $t("disagree") }}
                  </v-btn>
                  <v-btn v-if="!errorFlag" color="#132d7a" @click="sendForm()">
                    <span style="color: #fff">{{ $t("agree") }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div class="spiner" v-if="isSpiner">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </v-dialog>
          </section>
        </div>
      </div>

      <div class="toTop">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              color="#132d7a"
              class="mb-3 ml-3 btn-text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon middium class="icon">{{ icons.home }}</v-icon>
              {{ $t("toTop_btn_label") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ $t("confirm_text") }}
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="dialog = false">
                {{ $t("disagree") }}
              </v-btn>

              <v-btn color="#132d7a" @click="$router.push('/akiya')">
                <span style="color: #fff">{{ $t("agree") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="previous-button mr-3" @click="$router.back()">
          <span class="previous-button-text">{{
            $t("previos_btn_label")
          }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Title from "../components/title/Title.vue";
import { mdiHomeVariantOutline } from "@mdi/js";
import axios from "axios";

export default {
  name: "Estimate",
  components: {
    Title,
  },
  data() {
    return {
      icons: {
        home: mdiHomeVariantOutline,
      },
      rules: {
        name: [
          (v) => !!v || "入力してください",
          (v) => (v && v.length <= 30) || "30文字以内で入力してください",
        ],
        phone: [
          (v) => !!v || "入力してください",
          (v) =>
            /^(0{1}\d{9,10})$/.test(v) || "電話番号の形式が正しくありません",
        ],
        email: [
          (v) => !!v || "入力してください",
          (v) =>
            /.+@.+\..+/.test(v) ||
            "メールアドレスのフォーマットが正しくありません",
        ],
      },
      errorFlag: false,
      name: "",
      phone: "",
      email: "",
      dialog: false,
      confDialog: false,
      isSpiner: false,
    };
  },
  methods: {
    checkForm() {
      var checkLists = [];
      //名前のvalidation
      if (this.name && this.name.length <= 255) {
        checkLists[0] = true;
      } else {
        checkLists[0] = false;
      }

      //電話番号のvalidation
      if (
        this.phone &&
        this.phone.match(/^(0{1}\d{9,10})$/) &&
        this.phone.length <= 15
      ) {
        checkLists[1] = true;
      } else {
        checkLists[1] = false;
      }

      //mailのvalidation
      if (
        this.email &&
        this.email.match(/.+@.+\..+/) &&
        this.email.length <= 255
      ) {
        checkLists[2] = true;
      } else {
        checkLists[2] = false;
      }

      this.errorFlag = checkLists.some(function(value) {
        return value === false;
      });
    },
    sendForm() {
      if (!this.errorFlag) {
        const obj = {
          app: "37",
          record: {
            name: {
              value: this.name,
            },
            phone: {
              value: this.phone,
            },
            email: {
              value: this.email,
            },
            login_email: {
              value: sessionStorage.getItem("login_email"),
            },
          },
        };
        var data = JSON.stringify(obj);
        const key = "estimate";
        const url = `${this.$config.lambda_url}?key=${key}&data=${data}`;
        console.log("url");
        console.log(url);
        axios
          .get(url)
          .then(() => {
            this.$router.push("/send02");
            this.isSpiner = false;
          })
          .catch((error) => {
            this.isSpiner = false;
            alert("送信エラー: 管理者にお問い合わせください\n" + error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.estimate {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .estimate-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    background-blend-mode: lighten;
    background-size: cover;
    background-position: center;

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      padding: 20px;
      background-color: rgba(255, 255, 255);
      box-shadow: 0px 0px 30px 5px #e8f2f9;
      border-radius: 10px;
      z-index: 5;

      .estimate-title {
        font-size: max(22px, 2.5vw);
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
        color: #132d7a;
      }

      .estimate-desc {
        padding: 0 10px;
        font-size: max(12px, 1.7vw);
        color: #132d7a;
        font-weight: bold;
        text-align: center;
        white-space: pre-wrap;
      }

      .form {
        width: 80%;
        max-width: 800px;

        .form-sub-title {
          font-size: max(14px, 1.2vw);
          font-weight: bold;
          margin-bottom: 5px;
        }

        .form_input {
          background-color: #fff;
          border: solid 1px gray;
          border-radius: 5px;
          width: 100%;
          height: 45px;
          color: #000;
          padding-left: 10px;
          outline: none;

          &::placeholder {
            color: gray;
            opacity: 0.7;
          }

          &:focus::placeholder {
            color: gray;
          }

          &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset;
            -webkit-text-fill-color: #714a44 !important;
          }
        }

        .form_error {
          height: 20px;
          font-size: clamp(10px, 0.9vw);
          text-align: left;
          color: #c90000;
          margin-bottom: 20px;
        }

        .form-send {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 40px;
          margin-top: 50px;

          .form-send-button {
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 30%;
            background-color: #132d7a;
            border-radius: 15px;
            cursor: pointer;
            transition: 0.5s;

            &:hover {
              opacity: 0.8;
            }

            .text_to_send {
              color: #fafafa;
              font-size: max(15px, 1.5vw);
              font-weight: bold;
              letter-spacing: 0.6rem;
            }
          }
        }
      }
    }

    .toTop {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .icon {
        margin-right: 5px;
      }
      .btn-text {
        font-size: 1.1em;
        display: flex;
        text-align: center;
      }

      .previous-button {
        margin-bottom: 10px;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 15%;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.5s;
        color: #fafafa;
        background-color: #676767;

        &:active {
          opacity: 0.6;
        }

        .previous-button-text {
          font-size: max(18px, 1.5vw);
          font-weight: bold;
          letter-spacing: 0.2rem;
        }
      }
    }
  }
}
</style>
